import {
    BehaviorSubject,
    fromEvent as observableFromEvent,
    merge,
    of as observableOf,
} from 'rxjs';

import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    switchMap,
} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, viewChild, ViewChild} from '@angular/core';
import {SettingService} from '../setting.service';
import {DeleteConfirmComponent, SharedService} from '../../shared';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {BCODE_HEADER_COLS, BCODE_TABLE_CONF} from './table-conf';
import {rowsAnimation} from '../../animations';
import {BillingCodeDTO, DEFAULT_SEARCH_CONFIG} from '../../model';
import {BillingCodeEditComponent} from './billing-code-edit/billing-code-edit.component';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SearchService} from "../../shared/advanced-search/search.service";

@Component({
    selector: 'ft-billing-codes',
    templateUrl: './billing-codes.component.html',
    styleUrls: ['./billing-codes.component.scss'],
    animations: [rowsAnimation],
})
export class BillingCodesComponent implements AfterViewInit, OnDestroy {
    cols: any[];
    displayedColumns = [];

    dataSource = new MatTableDataSource<BillingCodeDTO>();

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    trackById = (_: number, item: any): string => item.id;
    filterForm: FormGroup;

    searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');

    constructor(
        private service: SettingService,
        private sharedService: SharedService,
        private _searchService: SearchService,
        public dialog: MatDialog,
        private _fb:FormBuilder
    ) {

        this.filterForm = this._fb.group({key:''});

        setTimeout(() => {
            this._searchService.searchInputConfig.set({
                placeholder: 'search.billing_codes',
                expandable: false,
                hidden: false,
                template: this.searchTemplate()
            });

            this._searchService.genericSearchObs.subscribe(value => this.filterForm.get('key').patchValue(value));
        });

        this.displayedColumns = BCODE_TABLE_CONF;
        this.cols = BCODE_HEADER_COLS;
    }

    ngOnDestroy() {
        this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
    }

    editBillingCode(code: BillingCodeDTO) {
        this.dialog
            .open(BillingCodeEditComponent, {data: code, disableClose: true})
            .afterClosed()
            .subscribe(res => {
                if (res) this.filterChange.next('');
            });
    }

    deleteBillingCode(code: BillingCodeDTO) {
        this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this.service
                        .deleteBillingCode(code.id)
                        .subscribe(res => this.filterChange.next(''));
                }
            });
    }

    ngAfterViewInit() {
        this.filterForm.get('key').valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(value => {
                if (!this.dataSource) return;
                this.paginator.pageIndex = 0;
                this.filterChange.next(value);
            });

        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        const observedFilters = [
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.filterChange.asObservable(),
        ];

        merge(...observedFilters)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.sharedService.getPaginatedBillingCodes(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        this.filterChange.getValue()
                    );
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];

                    return data['content'] as BillingCodeDTO[];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            )
            .subscribe(data => (this.dataSource.data = data));
    }
}
