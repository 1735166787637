import { Component } from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SettingService } from '../setting.service';
import {DEFAULT_SEARCH_CONFIG, ReasonForExamDTO} from '../../model';
import { FormBuilder, FormGroup } from '@angular/forms';
import {SearchService} from "../../shared/advanced-search/search.service";

@Component({
	selector: 'ft-reason-for-exam',
	templateUrl: './reason-for-exam.component.html',
	styleUrls: ['./reason-for-exam.component.scss'],
})
export class ReasonForExamComponent {
	reasonForExams: ReasonForExamDTO[] = [];
	reasonForExamForm!: FormGroup;
	currentReasonForExamColor: string = '#32c5da';

	constructor(
		private _settingService: SettingService,
		private _sharedService: SharedService,
		private _fb: FormBuilder,
		private _dialog: MatDialog,
		private _snack: MatSnackBar
	) {
		this.reasonForExamForm = this._fb.group(new ReasonForExamDTO());
		this.getReasonForExams();
	}

	deleteReasonForExam(reasonForExam: ReasonForExamDTO) {
		this._dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this._settingService
						.deleteReasonForExam(reasonForExam.id)
						.subscribe(res => {
							if (res) {
								this._snack.open(
									'Ligne supprimé avec succès!',
									'',
									{ duration: 1500 }
								);
								this.getReasonForExams();
							} else {
								this._snack.open(
									'Sorry! Item already used !',
									'Ok',
									{ duration: 2000 }
								);
							}
						});
				}
			});
	}

	selectReasonForExam(
		reasonForExam: ReasonForExamDTO = new ReasonForExamDTO()
	) {
		this.currentReasonForExamColor = reasonForExam.color;
		this.reasonForExamForm.patchValue(reasonForExam);
	}

	saveRFE(reasonForExam: ReasonForExamDTO) {
		reasonForExam.color = this.currentReasonForExamColor;
		this._sharedService.saveReasonForExam(reasonForExam).subscribe(_ => {
			this._snack.open('Saved successfully !', '', { duration: 2000 });
			this.getReasonForExams();
		});
	}

	private getReasonForExams() {
		this._sharedService
			.getReasonForExams()
			.subscribe(data => (this.reasonForExams = data));
	}

	addReasonForExam() {
		this.reasonForExams.push(new ReasonForExamDTO());
	}
}
