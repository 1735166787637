import {
    AfterViewInit,
    Component,
    inject, OnDestroy,
    OnInit, TemplateRef, viewChild,
    ViewChild,
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {
    BehaviorSubject,
    merge,
    of as observableOf,
} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {DeleteConfirmComponent, SharedService} from '../../shared';
import {MatDialog} from '@angular/material/dialog';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    switchMap,
} from 'rxjs/operators';
import {union} from 'lodash';
import {LABEL_MODULES, LABELS_TABLE_COLS} from './table-conf';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LabelEditComponent} from '../../shared/label-edit/label-edit.component';
import {DEFAULT_SEARCH_CONFIG, LabelDTO} from '../../model';
import {SearchService} from "../../shared/advanced-search/search.service";

@Component({
    selector: 'ft-label-setting',
    templateUrl: './label-setting.component.html',
    styleUrls: ['./label-setting.component.scss'],
})
export class LabelSettingComponent implements AfterViewInit, OnDestroy {
    dataSource = new MatTableDataSource<LabelDTO>();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    filterChange = new BehaviorSubject('');
    editChange = new BehaviorSubject('');

    cols = union(
        LABELS_TABLE_COLS.map(it => it.value),
        ['action']
    );

    displayedColumns: any[] = LABELS_TABLE_COLS;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    labelModules = LABEL_MODULES;

    private _shared = inject(SharedService);
    private _searchService = inject(SearchService);
    private _fb = inject(FormBuilder);
    private _dialog = inject(MatDialog);
    filterForm: FormGroup;

    searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');


    constructor() {
        this.filterForm = this._fb.group({key: new FormControl<string>(''), labelModule: new FormControl<any>([])});

        setTimeout(() => {
            this._searchService.searchInputConfig.set({
                placeholder: 'search.labels',
                expandable: true,
                hidden:false,
                template: this.searchTemplate()
            });

            this._searchService.genericSearchObs.subscribe(value => this.filterForm.get('key').patchValue(value));
        });
    }

    ngOnDestroy() {
        this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
    }

    ngAfterViewInit() {
        this.filterForm.get('key').valueChanges
            .pipe(debounceTime(400), distinctUntilChanged())
            .subscribe(value => {
                if (!this.dataSource) return;
                this.dataSource.filter = value;
                this.filterChange.next(value);
            });

        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        merge(
            this.sort.sortChange.asObservable(),
            this.paginator.page.asObservable(),
            this.editChange,
            this.filterForm.valueChanges
        )
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    const {key, labelModule} = this.filterForm.getRawValue();
                    return this._shared.getLabels(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        `${key}_${labelModule.join('@')}`
                    );
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data['totalElements'];
                    return data['content'] as LabelDTO[];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            )
            .subscribe(data => (this.dataSource.data = data));
    }

    editLabel(label: LabelDTO) {
        this._dialog
            .open(LabelEditComponent, {data: label, disableClose: true})
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    this.editChange.next(res);
                }
            });
    }

    deleteLabel(label: LabelDTO) {
        this._dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .subscribe(ok => {
                if (ok) {
                    this._shared.deleteLabel(label.id).subscribe(res => {
                        if (res) {
                            this.editChange.next(null);
                        }
                    });
                }
            });
    }

    getLabelModuleColor(module: string): string {
        switch (module) {
            case 'REPORT':
                return '#0b9eec';
            case 'APPOINTMENT':
                return '#f58b00';
            case 'PATIENT':
                return '#ff00b2';
            default:
                return '#00c53a';
        }
    }
}
