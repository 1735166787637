<mat-drawer-container class="ic-drawer-container" [hasBackdrop]="false">
    <mat-drawer #drawer [opened]="false" [mode]="'over'" [position]="'end'">
        <mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
            <h3 class="font-semibold text-lg">{{ "IMAGINGCENTERDETAILS" | translate }}</h3>
            <span class="fx-grow-1"></span>
            <button mat-icon-button (click)="drawer.close()">
                <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
            </button>
        </mat-toolbar>
        <div class="drawer-container fx-layout-column fx-gap-12">
            <div
                    class="fx-layout-column fx-content-start fx-items-stretch fx-grow-1"
                    [formGroup]="form"
            >
                <div class="fx-layout-row-nowrap fx-gap-8">
                    <mat-form-field class="fx-grow-1">
                        <mat-label>{{ "NAME" | translate }}</mat-label>
                        <textarea
                                class="fx-fill-width"
                                matInput
                                placeholder="{{ 'NAME' | translate }}"
                                formControlName="name"
                        ></textarea>
                    </mat-form-field>

                    <mat-form-field class="fx-grow-1">
                        <mat-label dir="rtl">{{
                                "إسم المركز أو الوحدة الاستشفائية"
                            }}
                        </mat-label>
                        <textarea
                                class="fx-fill-width"
                                matInput
                                placeholder="إسم المركز أو الوحدة الاستشفائية"
                                dir="rtl"
                                formControlName="arabicName"
                        ></textarea>
                    </mat-form-field>
                </div>

                <div class="fx-layout-row-nowrap fx-gap-8">
                    <div class="fx-grow-1">
                        <mat-form-field class="fx-fill-width">
                            <mat-label>{{ "PHONE" | translate }}</mat-label>
                            <input
                                    matInput
                                    placeholder="{{ 'PHONE' | translate }}"
                                    formControlName="phone"
                            />
                        </mat-form-field>
                    </div>
                    <div class="fx-grow-1">
                        <mat-form-field class="fx-fill-width">
                            <mat-label>{{ "FAX" | translate }}</mat-label>
                            <input
                                    matInput
                                    placeholder="{{ 'FAX' | translate }}"
                                    formControlName="fax"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div class="fx-layout-row-nowrap fx-gap-8">
                    <div class="fx-grow-1">
                        <mat-form-field class="fx-fill-width">
                            <mat-label>{{ "EMAIL" | translate }}</mat-label>
                            <input
                                    matInput
                                    placeholder="{{ 'EMAIL' | translate }}"
                                    formControlName="email"
                            />
                        </mat-form-field>
                    </div>
                    <div class="fx-grow-1">
                        <mat-form-field class="fx-fill-width">
                            <mat-label>{{ "WEBSITE" | translate }}</mat-label>
                            <input
                                    matInput
                                    placeholder="{{ 'WEBSITE' | translate }}"
                                    formControlName="website"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <mat-form-field class="fx-fill-width">
                    <mat-label>{{ "ADDRESS" | translate }}</mat-label>
                    <textarea
                            matInput
                            placeholder="{{ 'ADDRESS' | translate }}"
                            formControlName="address"
                    ></textarea>
                </mat-form-field>

                <fieldset class="fx-layout-column-nowrap">
                    <legend>{{ "GMAIL_SETTING" | translate }}</legend>

                    <mat-form-field>
                        <mat-label>{{ "EMAIL" | translate }}</mat-label>
                        <input
                                [placeholder]="'EMAIL' | translate"
                                formControlName="communicatingEmail"
                                matInput
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "GMAIL_PASSWORD" | translate }}</mat-label>
                        <input
                                [placeholder]="'GMAIL_PASSWORD' | translate"
                                formControlName="gmailPassword"
                                matInput
                                type="password"
                        />
                    </mat-form-field>
                </fieldset>

                <fieldset class="fx-layout-column-nowrap">
                    <legend>{{ "SMS_SETTING" | translate }}</legend>

                    <mat-form-field>
                        <mat-label>{{ "OADC" | translate }}</mat-label>
                        <input
                                [placeholder]="'OADC' | translate"
                                formControlName="oadc"
                                matInput
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ "SMS_USERNAME" | translate }}</mat-label>
                        <input
                                [placeholder]="'SMS_USERNAME' | translate"
                                formControlName="smsUsername"
                                matInput
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "ACCOUNT_PASSWORD" | translate }}</mat-label>
                        <input
                                [placeholder]="'ACCOUNT_PASSWORD' | translate"
                                formControlName="smsPassword"
                                matInput
                                type="password"
                        />
                    </mat-form-field>
                </fieldset>
            </div>

            <div class="fx-layout-row fx-content-end fx-gap-4">
                <button color="warn" (click)="drawer.close()" mat-raised-button>
                    {{ "CANCEL" | translate }}
                </button>
                <button
                        (click)="onSave(form.value)"
                        [disabled]="!form.valid"
                        color="primary"
                        mat-raised-button
                >
                    {{ "SAVE" | translate }}
                </button>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <mat-toolbar class="fx-card-toolbar fx-gap-4" color="primary">
            <h3 class="font-semibold text-2xl">{{ "IMAGING_CENTERS" | translate }}</h3>
        </mat-toolbar>

        <div class="centers-container">
            @for (center of imagingCenters; track $index) {
                <mat-card class="center-card">
                    <mat-card-header>
                        <mat-card-title>{{ center.name }}</mat-card-title>
                        <mat-card-subtitle>{{ center.address }}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="center-infos">
                            <div class="info-line">
                                <div>{{ "EMAIL" | translate }} :</div>
                                <div>{{ center.email }}</div>
                            </div>

                            <div class="info-line">
                                <div>{{ "PHONE" | translate }} :</div>
                                <div>{{ center.phone }}</div>
                            </div>

                            <div class="info-line">
                                <div>{{ "FAX" | translate }} :</div>
                                <div>{{ center.fax }}</div>
                            </div>

                            <div class="info-line">
                                <div>{{ "WEBSITE" | translate }} :</div>
                                <div>{{ center.website }}</div>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions align="end">
                        @if (center.id === 1) {
                            <mat-icon
                                    matTooltip="{{ 'PRIMARY' | translate }}"
                                    fontIcon="mdi-shield-lock"
                                    fontSet="mdi"
                            ></mat-icon>
                        }
                        @if (center.id !== 1) {
                            <button
                                    mat-icon-button
                                    color="warn"
                                    (click)="deleteCenter(center)"
                                    [disabled]="!deletable"
                                    [matTooltip]="'DELETE' | translate"
                            >
                                <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                            </button>
                        }
                        <span class="fx-grow-1"></span>
                        <button
                                mat-icon-button
                                color="accent"
                                (click)="duplicateCenter(center); drawer.open()"
                                [matTooltip]="'DUPLICATE' | translate"
                        >
                            <mat-icon fontIcon="mdi-content-duplicate" fontSet="mdi"></mat-icon>
                        </button>
                        <button
                                mat-icon-button
                                color="primary"
                                (click)="drawer.toggle(); editCenter(center)"
                                [matTooltip]="'EDIT' | translate"
                        >
                            <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            }
        </div>

        <button mat-fab (click)="drawer.open(); editCenter({})"
                style="position: fixed; bottom: 46px; right: 56px">
            <mat-icon
                    fontIcon="mdi-plus"
                    fontSet="mdi"
            ></mat-icon></button>
    </mat-drawer-content>
</mat-drawer-container>
