<mat-toolbar class="fx-card-toolbar fx-gap-4" color="primary">
    <h3 class="font-semibold text-2xl">{{ "GENERAL_SETTING" | translate }}</h3>
</mat-toolbar>

@if (generalSetting) {
    <div
            [formGroup]="calFormGroup"
            class="overflow-auto p-4"
            style="height: calc(100vh - 145px)"
    >
        <div class="setting-area">
            <h4 style="margin: 8px 0 16px 0">
                {{ "CALENDAR_SETTING" | translate }}
            </h4>

            <div class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
                <mat-form-field>
                    <mat-label>{{ "OPENINGTIME" | translate }}</mat-label>
                    <input
                            type="time"
                            matInput
                            placeholder="{{ 'OPENINGTIME' | translate }}"
                            formControlName="openingTime"
                            (change)="onSelectTime($event)"
                    />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ "CLOSINGTIME" | translate }}</mat-label>
                    <input
                            type="time"
                            matInput
                            placeholder="{{ 'CLOSINGTIME' | translate }}"
                            formControlName="closingTime"
                            (change)="onSelectTime($event)"
                    />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ "SCHEDULER_DEFAULT_VIEW" | translate }}</mat-label>
                    <mat-select
                            placeholder="{{ 'SCHEDULER_DEFAULT_VIEW' | translate }}"
                            (selectionChange)="onSelectSchedulerView($event)"
                            [(ngModel)]="generalSetting.schedulerDefaultView"
                            [ngModelOptions]="{ standalone: true }"
                    >
                        @for (option of ['CALENDAR', 'EXTERNAL_APPOINTMENTS', 'SCHEDULER'];
                            track $index) {
                            <mat-option [value]="option" class="option">
                                {{ option | translate }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8">
                <mat-form-field formGroupName="defaultView">
                    <mat-label>{{ "DEFAULTCALENDARVIEW" | translate }}</mat-label>
                    <mat-select
                            placeholder="{{ 'DEFAULTCALENDARVIEW' | translate }}"
                            (selectionChange)="onSelectView($event)"
                            formControlName="id"
                    >
                        @for (_view of views; track $index) {
                            <mat-option [value]="_view.id" class="option">
                                {{ _view.name }}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field formGroupName="minTimeSlot">
                    <mat-label>{{ "MINIMUMTIMESLOT" | translate }}</mat-label>
                    <mat-select
                            placeholder="{{ 'MINIMUMTIMESLOT' | translate }}"
                            (selectionChange)="onSelectTime($event)"
                            formControlName="id"
                    >
                        @for (_slot of slots; track $index) {
                            <mat-option [value]="_slot.id" class="option">
                                {{ _slot.value }} minutes
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="fx-layout-row fx-content-start fx-items-center">
                <h5 style="width: 140px">{{ "CLOSINGDAYS" | translate }}:</h5>

                <section class="fx-grow-1">
                    <mat-button-toggle-group
                            multiple
                            [vertical]="false"
                            #group="matButtonToggleGroup"
                    >
                        @for (day of days; track $index) {
                            <mat-button-toggle
                                    [disabled]="false"
                                    [checked]="day.checked"
                                    [value]="day.name"
                                    (change)="onSelectDay($event)"
                            >
                                {{ day.name }}
                            </mat-button-toggle>
                        }
                    </mat-button-toggle-group>
                </section>
            </div>

            <mat-form-field>
                <mat-label>{{ "session.logout" | translate }}</mat-label>
                <input
                        (change)="saveGeneralSetting(generalSetting); reload()"
                        [(ngModel)]="generalSetting.logoutTimeout"
                        [ngModelOptions]="{ standalone: true }"
                        [placeholder]="'session.logout' | translate"
                        matInput
                        type="number"
                        [min]="0"
                />
            </mat-form-field>
        </div>

        <div class="setting-area">
            <h4 style="margin: 16px 0">{{ "PRINTING_MODES" | translate }}</h4>

            <div class="fx-layout-row fx-content-start fx-items-center fx-gap-32">
                <div style="width: 400px">
                    <div class="fx-layout-row fx-content-start fx-items-center fx-gap-12">
                        <h5 style="width: 200px">{{ "REPORT_PRINT_MODE" | translate }}:</h5>
                        <section>
                            <mat-button-toggle-group
                                #report="matButtonToggleGroup"
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.reportPrintMode"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                @for (option of printingModes; track $index) {
                                    <mat-button-toggle
                                            [checked]="option.checked"
                                            [value]="option.name"
                                    >
                                        {{ option.name | translate }}
                                    </mat-button-toggle>
                                }
                            </mat-button-toggle-group>
                        </section>
                    </div>
                    <div class="fx-layout-row fx-content-start fx-items-center fx-gap-12">
                        <h5 style="width: 200px">{{ "RECEIPT_PRINT_MODE" | translate }}:</h5>
                        <section>
                            <mat-button-toggle-group
                                #receipt="matButtonToggleGroup"
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.receiptPrintMode"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                @for (option of printingModes; track $index) {
                                    <mat-button-toggle
                                            [checked]="option.checked"
                                            [value]="option.name"
                                    >
                                        {{ option.name | translate }}
                                    </mat-button-toggle>
                                }
                            </mat-button-toggle-group>
                        </section>
                    </div>
                    <div class="fx-layout-row fx-content-start fx-items-center fx-gap-12">
                        <h5 style="width: 200px">{{ "TICKET_PRINT_MODE" | translate }}:</h5>
                        <section>
                            <mat-button-toggle-group
                                #ticket="matButtonToggleGroup"
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.ticketPrintMode"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                @for (option of printingModes; track $index) {
                                    <mat-button-toggle
                                            [checked]="option.checked"
                                            [value]="option.name"
                                    >
                                        {{ option.name | translate }}
                                    </mat-button-toggle>
                                }
                            </mat-button-toggle-group>
                        </section>
                    </div>
                </div>

                <div style="width: 240px" class="fx-layout-column-nowrap">
                    <mat-form-field>
                        <mat-label>{{ "print.ticketPrinter" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.ticketPrinter"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'print.ticketPrinter' | translate"
                                matInput
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "print.receiptPrinter" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.receiptPrinter"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'print.receiptPrinter' | translate"
                                matInput
                        />
                    </mat-form-field>
                </div>

                <div class="fx-grow-1 fx-layout-column fx-gap-6">
                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.useBookletLastPage"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "print.printOnBookletLastPage" | translate }}
                    </mat-slide-toggle>

                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.useA4Receipt"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "print.useA4Receipt" | translate }}
                    </mat-slide-toggle>

                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.printBarcode"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "print.printBarcode" | translate }}
                    </mat-slide-toggle>
                </div>
            </div>
        </div>

        <div class="setting-area">
            <h4 style="margin: 16px 0">
                {{ "ID_GENERATION_MODES" | translate }}
            </h4>

            @if (generalSetting) {
                <div class="fx-layout-row fx-content-start fx-items-center fx-gap-12">
                    <h5 style="width: 200px">{{ "PATIENT_ID_GENERATION" | translate }}:</h5>
                    <section>
                        <mat-button-toggle-group
                            #gro="matButtonToggleGroup"
                            [(ngModel)]="generalSetting.patientIdGenerationMode"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                        >
                            @for (option of idGenerationModes; track $index) {
                                <mat-button-toggle [checked]="option.checked" [value]="option.name">
                                    {{ option.name | translate }}
                                </mat-button-toggle>
                            }
                        </mat-button-toggle-group>
                    </section>

                    @if (generalSetting.patientIdGenerationMode === 'AUTOMATIC') {
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label>{{ "PATIENT_ID_PREFIX" | translate }}</mat-label>
                            <input
                                    matInput
                                    [placeholder]="'PATIENT_ID_PREFIX' | translate"
                                    [(ngModel)]="generalSetting.patientIdPrefix"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="saveGeneralSetting(generalSetting)"
                            />
                        </mat-form-field>
                    }
                </div>
            }
            @if (generalSetting) {
                <div class="fx-layout-row fx-content-start fx-items-center fx-gap-12">
                    <h5 style="width: 200px">{{ "AN_GENERATION_MODE" | translate }}:</h5>
                    <section>
                        <mat-button-toggle-group
                            #group="matButtonToggleGroup"
                            [(ngModel)]="generalSetting.accessionIdGenerationMode"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                        >
                            @for (option of idGenerationModes; track $index) {
                                <mat-button-toggle [checked]="option.checked" [value]="option.name">
                                    {{ option.name | translate }}
                                </mat-button-toggle>
                            }
                        </mat-button-toggle-group>
                    </section>
                    @if (generalSetting.accessionIdGenerationMode === 'AUTOMATIC') {
                        <mat-form-field subscriptSizing="dynamic">
                            <mat-label>{{ "AN_PREFIX" | translate }}</mat-label>
                            <input
                                    matInput
                                    [placeholder]="'AN_PREFIX' | translate"
                                    [(ngModel)]="generalSetting.accessionNumberPrefix"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="saveGeneralSetting(generalSetting)"
                            />
                        </mat-form-field>
                    }
                </div>
            }
        </div>

        <div class="setting-area">
            <h4 style="margin: 16px 0">{{ "shared.name" | translate }}</h4>

            <div class="fx-layout-row fx-content-start fx-items-start fx-gap-16">
                <div class="fx-grow-1 fx-layout-column fx-gap-8">
                    <mat-form-field>
                        <mat-label>{{ "shared.officeServer" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.officeStorageServer"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'shared.officeServer' | translate"
                                matInput
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ "shared.portal_hostname" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.portalHostname"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'shared.portal_hostname' | translate"
                                matInput
                        />
                    </mat-form-field>

                    @if (generalSetting.portalHostname) {
                        <div class="fx-layout-row fx-content-start fx-items-center fx-gap-2">
                            <mat-form-field>
                                <mat-label>{{ "shared.portal_username" | translate }}</mat-label>
                                <input
                                        (change)="saveGeneralSetting(generalSetting)"
                                        [(ngModel)]="generalSetting.portalUsername"
                                        [ngModelOptions]="{ standalone: true }"
                                        [placeholder]="'shared.portal_username' | translate"
                                        matInput
                                />
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>{{ "shared.portal_password" | translate }}</mat-label>
                                <input
                                        (change)="saveGeneralSetting(generalSetting)"
                                        [(ngModel)]="generalSetting.portalPassword"
                                        [ngModelOptions]="{ standalone: true }"
                                        [placeholder]="'shared.portal_password' | translate"
                                        matInput
                                        type="password"
                                />
                            </mat-form-field>
                        </div>
                    }

                    <mat-form-field>
                        <mat-label>{{ "shared.officeLockFile" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.officeLockFile"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'shared.officeLockFile' | translate"
                                matInput
                        />
                    </mat-form-field>

                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.efactActivated"
                            [ngModelOptions]="{ standalone: true }"
                            style="padding-bottom: 8px"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "shared.eFactActivated" | translate }}
                    </mat-slide-toggle>

                    @if (generalSetting.efactActivated) {
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "shared.eFactUrl" | translate }}</mat-label>
                            <input
                                    (change)="saveGeneralSetting(generalSetting)"
                                    [(ngModel)]="generalSetting.efactUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    [placeholder]="'shared.eFactUrl' | translate"
                                    matInput
                            />
                        </mat-form-field>
                    }

                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.ehealthBoxActivated"
                            [ngModelOptions]="{ standalone: true }"
                            style="padding-bottom: 8px"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "shared.eHealthBoxActivated" | translate }}
                    </mat-slide-toggle>

                    @if (generalSetting.ehealthBoxActivated) {
                        <mat-form-field appearance="outline">
                            <mat-label>{{ "shared.eHealthBoxUrl" | translate }}</mat-label>
                            <input
                                    (change)="saveGeneralSetting(generalSetting)"
                                    [(ngModel)]="generalSetting.ehealthBoxUrl"
                                    [ngModelOptions]="{ standalone: true }"
                                    [placeholder]="'shared.eHealthBoxUrl' | translate"
                                    matInput
                            />
                        </mat-form-field>
                    }

                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.almaActivated"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "shared.almaActivated" | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="fx-grow-1 fx-layout-column fx-gap-8">
                    <mat-form-field>
                        <mat-label>{{ "shared.currency" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting); reload()"
                                [(ngModel)]="generalSetting.currency"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'shared.currency' | translate"
                                matInput
                        />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ "shared.numberFormat" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.numberFormat"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'shared.numberFormat' | translate"
                                matInput
                        />
                    </mat-form-field>

                    <mat-slide-toggle
                            [(ngModel)]="generalSetting.updateExamsFromDicomStore"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="saveGeneralSetting(generalSetting)"
                    >{{ "shared.updateExamsFromDicomStore" | translate }}
                    </mat-slide-toggle>

                    <div class="fx-layout-row fx-content-start fx-items-center fx-gap-12">
                        <h5 style="width: 100px">{{ "shared.uiLanguage" | translate }}:</h5>
                        <section>
                            <mat-button-toggle-group
                                    [(ngModel)]="generalSetting.uiLanguage"
                                    [ngModelOptions]="{ standalone: true }"
                                    (change)="saveGeneralSetting(generalSetting); reload()"
                            >
                                @for (option of languages; track $index) {
                                    <mat-button-toggle
                                            [checked]="option.key === generalSetting.uiLanguage"
                                            [value]="option.key"
                                    >
                                        {{ option.value }}
                                    </mat-button-toggle>
                                }
                            </mat-button-toggle-group>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <div class="setting-area fx-layout-row-nowrap fx-content-space-between">
            <div class="fx-grow-1">
                <h4 style="margin: 16px 0">{{ "BILLING" | translate }}</h4>
                <mat-slide-toggle
                        [(ngModel)]="generalSetting.billingRequired"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="saveGeneralSetting(generalSetting)"
                >{{ "BILLING_REQUIRED" | translate }}
                </mat-slide-toggle>

                <h4 style="margin: 16px 0">
                    {{ "REPORTING.NAME" | translate }}
                </h4>
                <mat-slide-toggle
                        disabled
                        [(ngModel)]="generalSetting.paragraphAutocompleteEnabled"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="saveGeneralSetting(generalSetting)"
                >{{ "EDITOR_PARAGRAPH_AUTOCOMPLETE" | translate }}
                </mat-slide-toggle>

                <h4 style="margin: 16px 0">
                    {{ "PATIENT_WITH_MULTIPLE_EXAMS" | translate }}
                </h4>
                <mat-slide-toggle
                        [(ngModel)]="generalSetting.singleReport"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="saveGeneralSetting(generalSetting)"
                >{{ "SINGLE_REPORT" | translate }}
                </mat-slide-toggle>
            </div>
            <div class="fx-grow-1">
                <h4 style="margin: 16px 0">
                    {{ "PATIENT_STATUS_WAITING_DURATION" | translate }}
                </h4>
                <mat-form-field>
                    <mat-label>{{ "WAITING_DURATION_BEFORE_ALERT" | translate }}</mat-label>
                    <mat-select
                            placeholder="{{ 'WAITING_DURATION_BEFORE_ALERT' | translate }}"
                            [(ngModel)]="generalSetting.waitingDurationBeforeAlert"
                            [ngModelOptions]="{ standalone: true }"
                            (selectionChange)="saveGeneralSetting(generalSetting)"
                    >
                        @for (d of [15, 20, 25, 30, 45, 60]; track $index) {
                            <mat-option [value]="d" class="option">{{ d }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="setting-area">
            <div class="fx-layout-column-nowrap">
                <h4 style="margin: 16px 0">{{ "STATISTICS" | translate }}</h4>
                <div>
                    <mat-form-field>
                        <mat-label>{{ "DEFAULT_STATE" | translate }}</mat-label>
                        <mat-select
                                (selectionChange)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.statisticDefaultView"
                                [ngModelOptions]="{ standalone: true }"
                                placeholder="{{ 'DEFAULT_STATE' | translate }}"
                        >
                            @for (view of statisticViews; track $index) {
                                <mat-optgroup [label]="view.name | translate">
                                    @for (state of view.states; track $index) {
                                        <mat-option [value]="view.name + '@' + state.value">
                                            {{ state.value | translate }}
                                        </mat-option>
                                    }
                                </mat-optgroup>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>

                <h4 style="margin: 16px 0">
                    {{ "ID_CARD_SCANNER" | translate }}
                </h4>

                <mat-slide-toggle
                        (change)="saveGeneralSetting(generalSetting)"
                        [(ngModel)]="generalSetting.idScannerConfigured"
                        [ngModelOptions]="{ standalone: true }"
                >{{ "ID_CARD_SCANNER_CONFIGURED" | translate }}
                </mat-slide-toggle>

                @if (generalSetting.idScannerConfigured) {
                    <mat-form-field class="fx-grow-1">
                        <mat-label>{{ "SCANNER_ID_URL" | translate }}</mat-label>
                        <input
                                (change)="saveGeneralSetting(generalSetting)"
                                [(ngModel)]="generalSetting.scannerIdUrl"
                                [ngModelOptions]="{ standalone: true }"
                                [placeholder]="'SCANNER_ID_URL' | translate"
                                matInput
                        />
                    </mat-form-field>
                }
            </div>
        </div>

        <div class="setting-area">
            <div class="fx-layout-column-nowrap">
                <h4 style="margin: 16px 0">
                    {{ "QUEUE_SETTING" | translate }}
                </h4>

                <div style="font-weight: bold; padding: 8px 0; color: #607d8b">
                    {{ "RESOURCE_DISPLAY" | translate }}
                </div>
                <mat-radio-group
                        (change)="saveGeneralSetting(generalSetting)"
                        [(ngModel)]="generalSetting.queueResource"
                        [ngModelOptions]="{ standalone: true }"
                        class="fx-layout-row fx-content-start fx-items-end"
                >
                    @for (option of ['aet', 'modality', 'examType', 'performingPhysician',
                        'room']; track $index) {
                        <mat-radio-button [value]="option">
                            {{ option | translate }}
                        </mat-radio-button>
                    }
                </mat-radio-group>
            </div>
        </div>

        <div class="setting-area">
            <div class="fx-layout-column-nowrap">
                <h4 style="margin: 16px 0">{{ "SMS" | translate }}</h4>

                <div style="font-weight: bold; padding: 8px 0; color: #607d8b">
                    {{ "MODE" | translate }}
                </div>
                <mat-radio-group
                        (change)="saveGeneralSetting(generalSetting)"
                        [(ngModel)]="generalSetting.smsSendingMode"
                        [ngModelOptions]="{ standalone: true }"
                        class="fx-layout-row fx-content-start fx-items-end"
                >
                    @for (option of ['AUTOMATIC', 'MANUAL']; track $index) {
                        <mat-radio-button [value]="option">
                            {{ option | translate }}
                        </mat-radio-button>
                    }
                </mat-radio-group>

                @if (generalSetting.smsSendingMode === 'AUTOMATIC') {
                    <div style="font-weight: bold; padding: 8px 0; color: #607d8b">
                        {{ "TRIGGER" | translate }}
                    </div>
                    <mat-radio-group
                            (change)="saveGeneralSetting(generalSetting)"
                            [(ngModel)]="generalSetting.smsAutoTrigger"
                            [ngModelOptions]="{ standalone: true }"
                            class="fx-layout-row fx-content-start fx-items-end"
                    >
                        @for (option of ['REPORT_SIGNED', 'REPORT_VALIDATED']; track $index) {
                            <mat-radio-button [value]="option">
                                {{ option | translate }}
                            </mat-radio-button>
                        }
                    </mat-radio-group>
                }
            </div>
        </div>
    </div>
}
