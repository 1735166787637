<div class="flex flex-row justify-start items-stretch gap-1 flex-grow h-full">

    <div class="setting-sidenav flex-shrink-0 h-fit overflow-auto">
        <mat-nav-list>
            <div mat-subheader>{{ "SYSTEM" | translate }}</div>
            @for (item of systemItems; track $index) {
                <mat-list-item
                        class="h-[30px]"
                        routerLink="{{ item.route }}"
                        routerLinkActive="ftx-list-item-active"
                        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
                        (click)="setHeaderTitle(item)"
                >
                    <mat-icon
                            matListItemIcon
                            fontIcon="{{ item.icon }}"
                            fontSet="mdi"
                            [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
                    ></mat-icon>
                    <div matListItemTitle>{{ item.title | translate }}</div>
                </mat-list-item>
            }

            <div mat-subheader>{{ "EXAM_SETTING" | translate }}</div>
            @for (item of procedureItems; track $index) {
                <mat-list-item
                        class="h-[30px]"
                        routerLink="{{ item.route }}"
                        routerLinkActive="ftx-list-item-active"
                        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
                        (click)="setHeaderTitle(item)"
                >
                    <mat-icon
                            matListItemIcon
                            fontIcon="{{ item.icon }}"
                            fontSet="mdi"
                            [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
                    ></mat-icon>
                    <div matListItemTitle>{{ item.title | translate }}</div>
                </mat-list-item>
            }

            <div mat-subheader>{{ "NETWORK_SETTING" | translate }}</div>
            @for (item of peersInterfacing; track $index) {
                <mat-list-item
                        class="h-[30px]"
                        routerLink="{{ item.route }}"
                        routerLinkActive="ftx-list-item-active"
                        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
                        (click)="setHeaderTitle(item)"
                >
                    <mat-icon
                            matListItemIcon
                            fontIcon="{{ item.icon }}"
                            fontSet="mdi"
                            [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
                    ></mat-icon>
                    <div matListItemTitle>{{ item.title | translate }}</div>
                </mat-list-item>
            }
            @if (profile && (profile.manageUsers !== 'NONE' ||
                profile.manageProfile !== 'NONE')) {
                <div mat-subheader>
                    {{ "USER_AND_PROFILE" | translate }}
                </div>
            }
            @for (item of securityItems; track $index) {
                <mat-list-item
                        class="h-[30px]"
                        routerLink="{{ item.route }}"
                        routerLinkActive="ftx-list-item-active"
                        [ngStyle]="{
          display: profile && profile[item.authLabel] !== 'NONE' ? '' : 'none'
        }"
                        (click)="setHeaderTitle(item)"
                >
                    <mat-icon
                            matListItemIcon
                            fontIcon="{{ item.icon }}"
                            fontSet="mdi"
                            [ngStyle]="{
            background:
              'linear-gradient(45deg, black, ' + item.iconColor + ', white)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }"
                    ></mat-icon>
                    <div matListItemTitle>{{ item.title | translate }}</div>
                </mat-list-item>
            }
        </mat-nav-list>
    </div>
    <mat-card class="flex-grow fx-card">
        <router-outlet></router-outlet>
    </mat-card>

</div>
