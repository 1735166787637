import {Component, inject} from '@angular/core';
import { SharedService } from '../../shared';
import {AetDTO, DEFAULT_SEARCH_CONFIG, ModalityDTO} from '../../model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingService } from '../setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SearchService} from "../../shared/advanced-search/search.service";

@Component({
	selector: 'ft-modality-setting',
	templateUrl: './modality-setting.component.html',
	styleUrls: ['./modality-setting.component.scss'],
})
export class ModalitySettingComponent {
	public modalities: ModalityDTO[] = [];
	public aets: AetDTO[] = [];
	modalityForm!: FormGroup;
	currentModalityColor: string = '#21a5ce';


	constructor(
		private settingService: SettingService,
		private snack: MatSnackBar,
		private sharedService: SharedService,
		private fb: FormBuilder
	) {
		this.modalityForm = this.fb.group(new ModalityDTO());
		this.getModalities();

		this.settingService
			.getAets(20, 0, 'title', 'asc')
			.subscribe(data => (this.aets = data['content']));
	}

	selectModality(modality: ModalityDTO = new ModalityDTO()) {
		this.currentModalityColor = modality.color;
		this.modalityForm.patchValue(modality);
	}

	updateModality(modality: ModalityDTO) {
		modality.color = this.currentModalityColor;
		this.settingService.saveModality(modality).subscribe(_ => {
			this.snack.open('Saved successfully !', '', { duration: 2000 });
			this.getModalities();
		});
	}

	private getModalities() {
		this.sharedService
			.getModalities()
			.subscribe(data => (this.modalities = data));
	}
}
