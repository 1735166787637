import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SettingService } from '../setting.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AetEditComponent } from './aet-edit/aet-edit.component';

import {AetDTO, DEFAULT_SEARCH_CONFIG} from '../../model';
import { DeleteConfirmComponent } from '../../shared';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { AET_TABLE_CONF } from './table-conf';
import { union } from 'lodash';
import {
	catchError,
	debounceTime,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import {SearchService} from "../../shared/advanced-search/search.service";

@Component({
	selector: 'ft-aet-setting',
	templateUrl: './aet-setting.component.html',
	styleUrls: ['./aet-setting.component.scss'],
})
export class AetSettingComponent implements AfterViewInit {
	displayedColumns = [];
	availableColumns = [];
	dataSource = new MatTableDataSource<AetDTO>();

	private filterChange = new BehaviorSubject('');

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		private settingService: SettingService,
		private _searchService: SearchService,
		public dialog: MatDialog
	) {
		setTimeout(() => {
			this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
		});


		this.availableColumns = AET_TABLE_CONF;
		this.displayedColumns = union(
			AET_TABLE_CONF.filter(it => !it.hidden).map(it => it.value),
			['action']
		);
	}

	ngAfterViewInit() {
		this.sort?.sortChange.subscribe(_ => {
			this.paginator.pageIndex = 0;
		});

		const observedFilters = [
			this.sort?.sortChange.asObservable(),
			this.paginator?.page.asObservable(),
			this.filterChange.pipe(debounceTime(250)),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					return this.settingService.getAets(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction
					);
				}),
				map(data => data['content'] as AetDTO[]),
				catchError(() => {
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	editAet(aet: AetDTO = new AetDTO()) {
		this.dialog
			.open(AetEditComponent, {
				data: aet?.id,
			})
			.afterClosed()
			.subscribe(res => {
				if (res) this.filterChange.next('');
			});
	}

	deleteAet(aet) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this.settingService.deleteAet(aet.id).subscribe(_ => {
						this.filterChange.next('');
					});
				}
			});
	}
}
