import { Component, DestroyRef, inject } from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_SEARCH_CONFIG, RoomDTO } from '../../model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { assign } from 'lodash';
import { SearchService } from '../../shared/advanced-search/search.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
	selector: 'ft-room-setting',
	templateUrl: './room-setting.component.html',
	styleUrls: ['./room-setting.component.scss'],
})
export class RoomSettingComponent {
	rooms: RoomDTO[] = [];
	roomForm!: FormGroup;
	currentRoomColor: string = '#25ac5d';

	private _destroyRef = inject(DestroyRef);

	constructor(
		private service: SharedService,
		public dialog: MatDialog,
		private fb: FormBuilder,
	) {
		this.roomForm = this.fb.group(
			assign(new RoomDTO(), {
				name: [null, Validators.required],
			}),
		);
		this.getRooms();
	}

	private getRooms(): void {
		this.service.getRooms()
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe(data => (this.rooms = data));
	}

	saveRoom(room: RoomDTO) {
		room.color = this.currentRoomColor;
		this.service.createRoom(room).subscribe(res => {
			if (res) this.getRooms();
		});
	}

	deleteRoom(room: RoomDTO) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.pipe(
				filter(ok => !!ok),
				switchMap(() => {
					if (room.id) return this.service.deleteRoom(room.id);
					else return of(true);
				}),
				takeUntilDestroyed(this._destroyRef))
			.subscribe(value => {
				if (value) this.getRooms();
			});
	}

	addRoom() {
		this.rooms.push(new RoomDTO());
	}

	selectRoom(room: RoomDTO = new RoomDTO()) {
		this.currentRoomColor = room.color;
		this.roomForm.patchValue(room);
	}
}
